.csn-theme {
  .editorial-search-form {
    display: flex;
    margin-bottom: 8px;

    @media (min-width: 991px) {
      width: 350px;
    }

    &__input-wrapper {
      align-items: center;
      display: flex;
      flex-grow: 1;
      position: relative;

      input {
        border: 1px solid #dddddd;
        border-radius: 8px 0 0 8px;
        height: 100%;
        padding: 10px 5px 10px 10px;
        outline: none;
        width: 100%;

        &.clear-shown {
          padding-right: 25px;
        }

        &::placeholder {
          color: #333333;
          opacity: 0.8;
        }
      }
    }

    &__clear-btn {
      color: #a1b9ed !important;
      background: transparent;
      border: none;
      cursor: pointer;
      font-size: 27px;
      font-weight: 400;
      height: 100%;
      line-height: 40px;
      outline: none;
      padding-right: 4px;
      position: absolute;
      right: 3px;
      text-align: center;
      text-decoration: none !important;

      &.hidden {
        display: none;
      }

      &:focus {
        color: #1b83c4 !important;
      }
    }

    &__submit-btn.btn-primary {
      align-items: center;
      border-radius: 0 8px 8px 0;
      display: flex;
      margin: 0;
      padding: 11px 10px;
      vertical-align: middle;
      width: 40px;

      background: {
        image: url(./Images/icon-search.svg);
        repeat: no-repeat;
        size: 16px;
        position: center;
      }
    }
  }
}
