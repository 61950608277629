@import "@csn-theme/editors-rating/dist/EditorsRating.scss";
@import "./Components/EditorialSearchForm.scss";

:root {
    --primary: #aa0a24;
}

.editorial-header {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0 10px;

    h2,
    h4 {
        margin: 0;
        font-weight: 400;
    }

    a {
        position: relative;
        padding-right: 18px;
        font-weight: 600;

        i {
            position: absolute;
            right: 0;
            top: 5px;
        }
    }

    .submit-owner-review-action {
        margin-right: 20px;
    }
}

.editorial-section {
    margin-bottom: 50px; // Add margin to bottom of sections to support absolute carousel dots
}

/*Editor rating color override*/
.rating-circle__valueStroke {
    stroke: var(--primary) !important;
}

.rating-text__value {
    color: var(--primary) !important;
}

.card-slider {
    position: relative;
    margin-top: 25px;

    &-inner {
        display: flex;
        flex-flow: row nowrap;
        position: relative;
    }

    &-item {
        transform: translate3d(0, 0, 0);
        transition: none;

        &:hover {
            text-decoration: none;
        }

        &.small-card .card-body {
            &-row {
                > .card-footer-text {
                    display: inline-flex;

                    span {
                        font-size: 14px;
                        font-weight: unset;
                        text-transform: none;
                        display: inline-block;
                        padding: 0;
                        margin: auto 0;
                        min-width: fit-content;
                    }

                    > .card-footer-author {
                        min-width: auto;
                    }
                }
            }

            .card-subtitle {
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                color: #272727;
                font-size: 14px;
                line-height: 18px;
                margin-bottom: 10px;
                font-weight: normal;
            }

            .card-text {
                color: #808080;
                font-size: 12px;
                line-height: 18px;
                margin-bottom: 25px;
                overflow: hidden;
                text-overflow: ellipsis;
                -webkit-box-orient: vertical;
                display: -webkit-box;
                -webkit-line-clamp: 2;
            }

            .card-action {
                color: #027cc2;
                font-size: 14px;
                margin-bottom: 0px;

                &:after {
                    content: "";
                    color: #007cc2;
                    border-style: solid;
                    border-width: 1px 1px 0 0;
                    display: inline-block;
                    height: 8px;
                    transform: rotate(45deg);
                    width: 8px;
                    font-family: slick;
                    font-size: 20px;
                    line-height: 1;
                    opacity: 0.75;
                    margin-left: 5px;
                }
            }

            .owner-review-rating {
                margin-left: auto;
                display: flex;
                align-content: center;

                &-text {
                    color: #272727;
                    font-size: 16px;
                    font-weight: bold;
                    margin-right: 8px;
                }

                &-star {
                    width: 20px;
                    height: 20px;
                    background: {
                        image: url(./Images/icon-star.svg);
                        repeat: no-repeat;
                        size: 20px;
                        position: center;
                    }
                }
            }
        }

        &.big-card .card-body {
            &-row {
                > .card-footer-text {
                    display: inline-flex;

                    span {
                        font-size: 12px;
                        font-weight: unset;
                        text-transform: none;
                    }

                    > .card-footer-author {
                        min-width: auto;
                    }
                }
            }
        }

        &.big-card > a > .card-body {
            position: absolute;
            background: linear-gradient(0deg, black 0%, rgba(0, 0, 0, 0.5) 70%, rgba(0, 0, 0, 0) 100%);
            bottom: 0;
            width: 100%;
            padding-top: 8px !important;
            color: #fff;

            > .card-title {
                font-size: 24px;
                font-weight: 600;
            }

            > .card-body-row {
                > span {
                    font-size: 14px;
                    font-weight: 700;
                }

                > div {
                    line-height: 2;
                }

                > .card-date {
                    font-size: 12px;
                }

                > .card-sponsor-label {
                    font-size: 12px;
                    margin-right: 4px !important;
                }
            }
        }
    }

    .narrow {
        width: 85%;
    }

    .card {
        height: 100%;
        overflow: hidden;
        cursor: pointer;

        &-title {
            // 1 line = 1.2em
            max-height: 2.4em;
            overflow: hidden;
            font-size: 16px;
            height: 2.6em; // Set height to ensure consistent card heights
        }

        &-img-top {
            position: relative;

            > img {
                width: 100%;
            }

            &.video-overlay {
                &:before {
                    content: "";
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    bottom: 0;

                    background: {
                        image: url(./Images/video-overlay.svg);
                        repeat: no-repeat;
                        size: 50px;
                        position: center;
                    }
                }

                &.big:before {
                    background-size: 84px;
                }
            }
        }

        &-sponsored-label {
            position: absolute;
            left: 0;
            bottom: 0;
            margin: 8px 16px;
            padding: 3px 9px;
            border-radius: 3px;
            font-size: 12px;
            text-transform: uppercase;
            background-color: #87d200;
        }

        &-body {
            display: flex;
            flex-flow: column nowrap;
            padding-top: 8px !important;

            .card-sponsored-label-big {
                margin: 10px 0;
                padding: 3px 9px;
                border-radius: 3px;
                font-size: 12px;
                text-transform: uppercase;
                background-color: #87d200;
            }

            .article-media {
                color: #6e6e6e;

                &.right {
                    margin-left: auto;
                    color: #fff;
                }

                span {
                    display: inline-flex;
                    flex-flow: row nowrap;
                    align-items: center;
                    font-size: 13px;

                    &:not(:last-child) {
                        margin-right: 10px;
                    }
                }

                i {
                    font-size: 14px;
                    padding-right: 5px;
                }
            }

            &-row {
                display: flex;
                flex-flow: row nowrap;
                align-items: center;
                height: 28px;

                > *:not(:last-child) {
                    margin-right: 10px;
                }
            }

            span {
                font-size: 12px;
                font-weight: 600;
                text-transform: uppercase;

                &.carpool {
                    color: #1b83c4;
                }

                &.review {
                    color: #4cc8e8;
                }

                &.editors-rating {
                    margin-left: auto;

                    editors-rating {
                        height: 28px;
                        width: 28px;
                        display: block;
                    }
                }

                &.news,
                &.actualidad {
                    color: #ee3c27;
                }

                &.advice {
                    color: #5c9b1c;
                }

                &.pruebas,
                &.test-drive {
                    color: #4a90e2;
                }

                &.feature,
                &.consejos {
                    color: #e6176f;
                }

                &.comparativas {
                    color: #ff8a2a;
                }

                &.owner-review {
                    color: #ab6503;
                }
            }
        }
    }
    // Styling for slick carousels
    .carousel-button.slick-disabled {
        display: none !important;
    }

    .slick-arrow {
        background: #fff;
        border: none;
        border-radius: 100% !important;
        box-shadow: 0 0 0 1px #ddd;
        height: 50px;
        outline: none !important;
        width: 50px;
        z-index: 1;

        @media (min-width: 991px) {
            margin-top: 22px; // Align arrow with image
        }

        &.slick-next {
            background: #fff;
        }

        &.slick-prev {
            background: #fff;

            &:before {
                transform: rotate(-135deg);
                margin-left: 5px;
            }
        }

        &:hover {
            background: #f3f8fc;
            box-shadow: 0 0 0 1px var(--primary);
        }

        &:before {
            content: "";
            font-weight: 300;
            border-style: solid;
            border-width: 2px 2px 0 0;
            display: inline-block;
            height: 12px;
            transform: rotate(45deg);
            width: 12px;
            margin-left: -5px;
            margin-top: 7px;
            color: var(--primary);
        }

        &.slick-disabled {
            display: none !important;
        }
    }

    .slick-dots {
        margin-bottom: 0px !important;
        bottom: -35px;

        & li {
            margin: 0;
            width: 16px;

            &.slick-active button:before {
                color: var(--primary);
            }

            & button:hover:before {
                color: var(--primary);
            }

            & button:before {
                font-size: 9px;
                color: #808080;
            }
        }
    }
    // Add space between carousel items/slides
    .slick-list {
        margin: 0 -5px;
    }

    .slick-slide > div {
        padding: 0 5px;
    }
}

.csn-theme .card:hover {
    text-decoration: none;
}

.card-body-row.last-row {
    margin-top: auto;
    > img {
        width: 25px;
        border-radius: 25px;
    }
}

.truncate {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.submit-owner-review-modal {
    position: fixed;
    width: 1000px;
    height: 800px;
    border: 0px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 1;
    z-index: 1100;

    .modal-close {
        position: absolute;
        top: 0;
        right: 0;
        width: 30px;
        height: 30px;
        background: {
            image: url(./Images/icon-cross.svg);
            repeat: no-repeat;
            size: 25px;
            position: center;
        }

        &:hover {
            cursor: pointer;
        }
    }

    iframe {
        height: 100%;
        width: 100%;
        border: 0;
        overflow: hidden;
    }
}

.modal-overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 1;
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.8);
}
